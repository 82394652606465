import React from 'react';
import './footer.css'
const Footer = () => {
    return (
        <div className='footer'>
        <div className='block b1'><p>Copyright © 2019 EXPO GEORGIA Co.</p></div>
        <div className='block b2'><a href='expogeorgia.ge'>www.expogeorgia.ge</a></div>
        <div className='block b3'><p>Copyright © 2019 EXPO GEORGIA Co.</p></div>
        </div>
    );
}

export default Footer;
