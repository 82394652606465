import React from 'react';
import './menu.css'
const Menu = () => {
    return (
        <div className='menu'>
            <ul>
                <li><a href="default.asp">EXHIBITION AT A GLANCE</a></li>
                <li><a href="news.asp">FOR EXHIBITORS</a></li>
                <li><a href="contact.asp">FOR VISITORS</a></li>
                <li><a href="about.asp">CONTACT</a></li>
            </ul>
        </div>
    );
}

export default Menu;
