import React from 'react';
import "./home.css"
import Menu from './Menu';

const Home = () => {
    return (
        <div className='home'>
       
            <header>
            
                <img src='..\img\Agro 23 White.png' title='logo' alt='logo' className='logo'/>
                <Menu/>
            </header>
            <div className='banner'>
            <img src='..\img\Agro Event (GEO).jpg' title='logo' alt='logo' className='banner'/>
            </div>
        </div>
    );
}

export default Home;
